@import './variables';
@import 'react-h5-audio-player/src/styles.scss';

.rhap_container {
  padding: 0;
}

.rhap_controls-section {
  position: relative;
  align-items: stretch;
}

.rhap_play-pause-button {
  font-size: 40px;
  width: 35px;
  height: 35px;

}

.rhap_stacked .rhap_controls-section {
  margin-top: 0;
}


.rhap_time {
  font-size: 14px;
}

.rhap_progress-container .rhap_progress-bar{
  height: 5px;
}
.rhap_progress-bar {
  background-color: #a4a4a4;
}

.rhap_download-progress, .rhap_progress-indicator {
  width: 15px;
  height: 15px;
  margin-left: -5px;
  top: -5px;

}

.rhap_main {
  > .rhap_progress-section {
    position: absolute;
    top: 4px;
    width: 100%;
  }

  .rhap_time {
    font-size: 12px;
  }

  .rhap_current-time {
    position: absolute;
    top: 4px;
    left: 4px;
  }

  .rhap_total-time {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

@media (min-width: 701px) {
  .rhap_main.rhap_stacked{
    position: relative;
  }
  .rhap_container {
    height: 100%;
  }
  .rhap_main-controls {
    height: 100%;
    flex-grow: 1;
    background-color: #1d1d1d;
  }


  .rhap_main {
    > .rhap_progress-section {
      width: calc(100% - 300px);
      top: unset;
      bottom: 25px;
      right: 0;
      margin: 0;
      z-index: 1;
    }
  }

  .rhap_progress-container {
    position: absolute;
    width: calc(100% - 300px);
    height: 6px;
    bottom: 0;
    right: 0;
    margin: 0;
  }



}


@media (min-width: 701px) and (max-width: 905px ){
  .rhap_main-controls{
      align-items: flex-start;
      padding-top: 2px;
  }
  .rhap_progress-container{
    height: 2px;

  }
  .rhap_main {
    > .rhap_progress-section {
      bottom: 20px;
    }
  }
}



@media (min-width: 1001px) {


  .rhap_main {
    > .rhap_progress-section {
      width: calc(100% - 400px);
    }
  }

  .rhap_progress-container {

    width: calc(100% - 400px);

  }
}

@media (max-width: 700px) {
  .rhap_main.rhap_stacked{
    position: relative;
  }
  .rhap_container {
    position: fixed;
    background-color: rgb(40, 40, 40);
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 0;

  }
  .rhap_main {
    position: relative;
  }
  .rhap_main-controls {
    padding-right: 20px;
  }
  .rhap_stacked .rhap_controls-section {
    flex-direction: column;
    justify-content: flex-start;
  }


  .rhap_progress-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    align-items: flex-start;

    .rhap_progress-bar {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .rhap_progress-filled {

    }
  }

}

